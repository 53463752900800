import Logger from './logger';

export class Events {
  constructor() {
    this.events = {};
    this.globalCallbacks = [];
    this.eventId = 0;
  }

  /**
   * Emit an event with an optional payload.
   *
   * @param {Symbol} eventName
   * @param {object} payload
   */
  emit(eventName, payload) {
    if (typeof eventName !== 'symbol') {
      Logger.warn(
        `[Deprecation] Non-Symbol event names will be removed in version 2.0. Use ConcertAds.events instead.`
      );

      eventName = EventTypes[eventName] || eventName;
    }

    let eid = ++this.eventId;
    (this.events[eventName] || [])
      .concat(this.globalCallbacks)
      .forEach(cb => cb(eventName, { id: eid, payload: payload }));
  }

  /**
   * Listen for an event.
   *
   * @param {Symbol} eventName
   * @param {function} callback
   */
  on(eventName, callback) {
    if (typeof eventName !== 'symbol') {
      Logger.warn(
        `[Deprecation] Non-Symbol event names will be removed in version 2.0. Use ConcertAds.events instead.`
      );

      eventName = EventTypes[eventName] || eventName;
    }

    this.events[eventName] = this.events[eventName] || [];
    this.events[eventName].push(callback);
  }

  /**
   * Listen for an event once and only once.
   *
   * @param {Symbol} eventName Event name
   * @param {function} callback Callback
   */
  once(eventName, callback) {
    const on = (...args) => {
      this.off(eventName, on);
      callback.apply(this, args);
    };
    this.on(eventName, on);
  }

  /**
   * Stop listening for all events, a certain event, or a specific callback of a certain event.
   *
   * @param {string?} eventName Event Name to remove callbacks
   * @param {function?} callback Callback instance to remove
   */
  off(eventName, callback) {
    // No event name? Remove ALL callbacks
    if (!eventName) {
      this.events = {};
      return;
    }

    // No callback signature? Remove all callbacks for the given event name.
    if (!callback) {
      this.events[eventName] = [];
      return;
    }

    // Find the callback signature for the event name and remove it.
    const callbacks = this.events[eventName] || [];
    for (let idx = 0; idx < callbacks.length; idx++) {
      const cb = callbacks[idx];
      if (callback === cb) {
        callbacks.splice(idx, 1);
        break;
      }
    }
  }

  onAnyEvent(callback) {
    this.globalCallbacks.push(callback);
  }

  callbacksFor(eventName) {
    return this.events[eventName] || [];
  }
}

export const EventTypes = {
  slotWrapperCreated: Symbol('slotWrapperCreated'),
  slotInserted: Symbol('slotInserted'),
  slotPublishedToAdServer: Symbol('slotPublishedToAdServer'),
  bidManagerCreated: Symbol('bidManagerCreated'),
  bidSent: Symbol('bidSent'),
  bidComplete: Symbol('bidComplete'),
  adViewed: Symbol('adViewed'),
  adLoaded: Symbol('adLoaded'),
  adRendered: Symbol('adRendered'),
  slotCollapsed: Symbol('slotCollapsed'),
  slotRefreshed: Symbol('slotRefreshed'),
  slotObserved: Symbol('slotObserved'),
  settingsAvailable: Symbol('settingsAvailable'),
  scrollVelocityBelowThreshold: Symbol('scrollVelocityBelowThreshold'),
  remoteConfigLoaded: Symbol('remoteConfigLoaded'),
  remoteConfigTimeoutLoaded: Symbol('remoteConfigTimeoutLoaded'),
  remoteConfigError: Symbol('remoteConfigError'),
  remoteConfigTimeout: Symbol('remoteConfigTimeout'),
  firstAdRendered: Symbol('firstAdRendered'),
  installing: Symbol('installing'),
  slotsInserted: Symbol('slotsInserted'),
  classifierValueAdded: Symbol('classifierValueAdded'),
  allClassifierValuesAdded: Symbol('allClassifierValuesAdded'),
};

export default new Events();
