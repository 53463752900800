import Logger from '../lib/logger';
import PluginBase from './base';
import * as prebidFunctions from '../lib/prebid_helper';
import { fetchJson } from '../lib/fetch_json';
import { getEntryUrl } from '../classifiers/context';
import Events, { EventTypes } from '../lib/events';

export default class GooglePair extends PluginBase {
  onSettingsLoaded() {
    Logger.log('Installing plugin: Google Pair');

    if (!this.app.settings.prebid.biddingEnabled) return;

    if (this.app.settings.googlePairEnabled) {
      Events.on(EventTypes.bidManagerCreated, () => {
        this.googlePairSync();
      });
    }
  }

  googlePairSync() {
    this.app.bidManager.beforeFirstBid(() => {
      return new Promise((resolve, reject) => {
        Logger.log('Fetching data from Google Pair endpoint');

        const endpoint = 'https://web.archive.org/web/20230321154937/https://cdn.concert.io/hem/retrieve';

        let permutiveId = this.getPermutiveId();

        const payload = {
          permutive_id: permutiveId,
        };

        fetchJson(endpoint, { method: 'POST', data: payload, contentType: 'application/json' })
          .then(result => {
            const { data } = result;
            let numIntervalTries = 0;

            if (!data || !data.uids.length) {
              Logger.log('Successfully fetched but no matching UIDs');
              return resolve();
            }

            const checkPrebidLoaded = setInterval(() => {
              numIntervalTries++;
              if (numIntervalTries == 5) {
                clearInterval(checkPrebidLoaded);
                resolve();
              }
              if (prebidFunctions.prebidScriptLoaded()) {
                clearInterval(checkPrebidLoaded);
                this.updatePrebidUserSync(data.uids);
                this.addPairTargeting(data.uids);
                resolve();
              }
            }, 500);

            Logger.log('Successfully updated Prebid User Sync in Google Pair Plugin');
          })
          .catch(err => {
            Logger.error(`GooglePair failed to fetch from ${endpoint}`, { error: err.message });
            reject();
          });
      });
    });
  }

  getPermutiveId() {
    const PERMUTIVE_ID_KEY = 'permutive-id';

    const permutiveId = localStorage.getItem(PERMUTIVE_ID_KEY);

    return permutiveId;
  }

  updatePrebidUserSync(uids) {
    let userSync = prebidFunctions.getUserSync();
    if (!userSync) return;

    const updatedUserSync = {
      params: {
        eids: [
          {
            source: getEntryUrl(),
            uids: uids,
          },
        ],
      },
    };

    if (userSync.userIds.some(user => user.name === 'pubProvidedId')) {
      Object.assign(userSync.userIds.find(item => item.name === 'pubProvidedId'), updatedUserSync);
    } else {
      Logger.log(`pubProvidedId not found in RDM`);
    }

    prebidFunctions.updateUserSync(userSync);
    prebidFunctions.refreshUserIds();
  }

  addPairTargeting(uids) {
    Logger.log(`Adding targeting keys for Google Pair plugin with uids: ${uids}`);
    const advertiserIds = uids.map(advertiserId => {
      return advertiserId.id.split(':')[0];
    });

    this.app.addVariable('pair', advertiserIds);
  }
}
