import Events, { EventTypes } from '../lib/events';
import Logger from '../lib/logger';
import PluginBase from './base';
import ScriptLoader from '../lib/script_loader';

let signalsLoaded = false;
const signalsLoadedCallbacks = [];

export default class DoubleVerifyTargeting extends PluginBase {
  onSettingsLoaded() {
    Logger.log('Installing plugin: Double Verify Targeting');

    if (!this.isDoubleVerifyEligible()) return;

    this.loadDoubleVerifyScript();
    this.setUpDBWindowObject();
    this.loadDoubleVerifySignals();
    this.app.beforeAdsRequested.push(this.blockAdsRequestUntilSignalsLoaded());
  }

  /**
   * Check if we want to load Double Verify Targeting
   */
  isDoubleVerifyEligible() {
    return this.app.settings.doubleVerify?.scriptUrl && this.app.settings.doubleVerify?.enabled;
  }

  /**
   * Set up Double Verify window object
   */
  setUpDBWindowObject() {
    window.PQ = window.PQ || { cmd: [] };
  }

  /**
   * Load DoubleVerify Script
   */
  loadDoubleVerifyScript() {
    const doubleVerifyDependency = {
      url: this.app.settings.doubleVerify?.scriptUrl,
      timeout: this.app.settings.doubleVerify?.scriptTimeout,
    };

    new ScriptLoader()
      .load(doubleVerifyDependency)
      .then(url => {
        Logger.log(`Script ${url} has loaded successfully.`);
      })
      .catch(error => {
        this.signalsLoadedCallback();
        Logger.log(`Error loading external script: ${error}`);
      });
  }

  /**
   * Load Double Verify Signals
   */
  loadDoubleVerifySignals() {
    Events.on(EventTypes.slotsInserted, () => {
      Logger.log(`Requesting Double Verify targeting.`);

      window.PQ.cmd.push(() => {
        try {
          window.PQ.loadSignals(['ids', 'bsc', 'vlp', 'abs'], this.signalsLoadedCallback);
        } catch (e) {
          this.signalsLoadedCallback();
          Logger.error(`There has been an error loading Double Verify signals: ${e}`);
        }
      });
    });
  }

  /**
   * Callback run when the Double Verify signals are loaded
   * Making sure we don't block ad requests anymore
   */
  signalsLoadedCallback() {
    signalsLoaded = true;
    signalsLoadedCallbacks.forEach(f => f());
    Logger.log(`Double Verify signals have been loaded.`);
  }

  /**
   * Blocking Ad Requests until we get Double Verify targeting
   */
  blockAdsRequestUntilSignalsLoaded() {
    return new Promise(resolve => {
      if (signalsLoaded) {
        resolve();
      } else {
        signalsLoadedCallbacks.push(resolve);
      }
    });
  }
}
