import Logger from '../logger';
import Slot from '../slot';
import Constants from '../constants';
import GamAdSlotWrapper from '../slot_wrappers/gam_ad_slot_wrapper';

export default class ConcertPreviewSlot extends Slot {
  constructor(app, data) {
    super(app, data);

    this.frame = document.createElement('iframe');
  }

  /**
   * We take anything passed on as an extra option and add it into the data we want
   * to store in the recorded slots.
   * @return {object} Official slot object
   */
  applyDefaults() {
    super.applyDefaults();

    // Mark keys to exclude from data
    const exclude = ['name'];

    // These defaults live directly on the Slot object
    let slotDefaults = {
      sticky: false,
      sizes: [],
      customSizes: [],
      outOfPage: false,
    };

    for (var def in slotDefaults) {
      if (slotDefaults.hasOwnProperty(def)) this[def] = slotDefaults[def];
    }

    for (var d in this.data) {
      if (exclude.indexOf(d) > -1) continue;
      if (this.data.hasOwnProperty(d)) this[d] = this.data[d];
    }

    // Now, we add in the attributes that under no circumstances do we want to
    // be able to override as we register this slot.
    const state = {
      watcherEligible: false,
      loadTime: false,
    };

    this._state = { ...this._state, ...state };

    return this;
  }

  /**
   * Show preview content in this slot
   * @return {undefined}
   */
  render() {
    if (!super.render()) return;

    try {
      this.updateFrame();
      this.appendFrame();
      this.buildAd();
      this.scrollToAd();
    } catch (error) {
      Logger.log(`There was a problem building the preview for ${this.name}: ${error}`);
    }
  }

  /**
   * Determine whether this slot can be displayed on the page
   * @return {boolean}
   */
  canBeDisplayed() {
    return (
      super.canBeDisplayed() &&
      this.hasEligibleSize() &&
      this.requiredUrlParametersExist() &&
      this.data.previewEligible !== false
    );
  }

  /**
   * Do Required URL parameters to build preview exist?
   * @return {boolean}
   */
  requiredUrlParametersExist() {
    return (
      Constants.CONCERT_PREVIEW && getUrlParam('concert_ad_url') && getUrlParam('format_family') && getUrlParam('ad_id')
    );
  }

  /**
   * Previews are placed based on the sizes a slot accepts. If the preview ad is an Athena,
   * the ad will be placed in all slots on the page that accept an Athena size.
   *
   * @return {boolean}
   */
  hasEligibleSize() {
    const formatFamily = getUrlParam('format_family');
    const previewSizes = this.app.settings.previewSizes[formatFamily] || [];
    const matchingSizes = previewSizes.filter(allowedSize => this.hasSize(allowedSize));

    return !!matchingSizes.length;
  }

  /**
   * Add attributes to the preview frame
   * @return {undefined}
   */
  updateFrame() {
    this.frame.style.border = 'none';
    // The Concierge finds ads on the page based on this id
    this.frame.id = 'google_ads';
  }

  /**
   * Add preview frame to the DOM
   * @return {undefined}
   */
  appendFrame() {
    this.element.appendChild(this.frame);
  }

  /**
   * Build preview ad using URL parameters
   * @return {undefined}
   */
  buildAd() {
    Logger.log(`Building ad from URL: ${getUrlParam('concert_ad_url')}`);

    // SetTimeout is quickie Firefox bug fix
    setTimeout(() => {
      let frameDocument = this.frame.contentDocument;
      const adElements = [createAdDiv(), createAdScript()];

      adElements.forEach(element => frameDocument.body.appendChild(element));
    }, 250);

    this.rendered();
    this.markAsDisplayed();
  }

  /**
   * Scroll to a preview slot
   * @return {undefined}
   */
  scrollToAd() {
    if (window.scrollY > 300 && getUrlParam('disable_scroll') === null) return;

    Logger.log(`Scrolling to ${this.name}`);

    const previewOffset = this.element.getBoundingClientRect();
    const previewTop = previewOffset.top;
    const topMargin = window.innerHeight / 5;

    window.focus();
    window.scrollTo(0, previewTop - topMargin);
  }

  /**
   * Get slot wrapper
   * @param  {Slot}     slot       Instance of Slot
   * @param  {object}   config     Config
   * @param  {boolean}  existing   Whether we're creating from an existing DOM el
   * @return {GamAdSlotWrapper}         GamAdSlotWrapper instance
   */
  getSlotWrapper({ slot, config, existing }) {
    return new GamAdSlotWrapper({ slot, config, existing });
  }
}

/**
 * Create preview ad script
 * @return {undefined}
 */
function createAdScript() {
  let adScript = document.createElement('script');
  const adUrl = getUrlParam('concert_ad_url');
  adScript.src = adUrl;

  return adScript;
}

/**
 * Create preview ad div
 * @return {undefined}
 */
function createAdDiv() {
  let adDiv = document.createElement('div');
  if (getUrlParam('clickthrough')) adDiv.setAttribute('data-hymnal-dfp-click-url', getUrlParam('clickthrough'));
  adDiv.setAttribute('data-hymnal-ad-id', getUrlParam('ad_id'));

  return adDiv;
}

/**
 * Get URL parameter
 * @return {string}
 */
function getUrlParam(param) {
  const urlParams = new URLSearchParams(document.location.search);

  return urlParams.get(param);
}
