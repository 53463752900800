import Events, { EventTypes } from '../lib/events';
import Logger from '../lib/logger';
import PluginBase from './base';
import * as uuidv1 from 'uuid/v1';

export const CONCERT_ID_KEY = 'concert_rid';

export default class ConcertId extends PluginBase {
  onInstall() {
    Logger.log('Installing plugin: ConcertId');

    Events.on(EventTypes.slotPublishedToAdServer, (name, data) => {
      this.addUniqueIdToSlot(data);
    });
  }

  addUniqueIdToSlot(data) {
    const slotName = data.payload.name;
    const slot = this.app.slots.get(slotName);
    const id = uuidv1();

    Logger.log(`Setting slot ${slotName} unique Concert ID: ${id}`);

    slot.setTargeting(CONCERT_ID_KEY, id);
  }
}
