/* eslint-disable camelcase */

import Logger from '../logger';
import Slot from '../slot';
import ScriptLoader from '../script_loader';

export default class ConnatixSlot extends Slot {
  constructor(app, data) {
    super(app, data);
  }

  applyDefaults() {
    super.applyDefaults({
      state: {
        connatixGlobal: this.global,
        connatixScript: this.scriptUrl,
      },
    });
  }

  /**
   * Show an ad in this slot
   * @return {undefined}
   */
  render() {
    if (!super.render()) return;

    Logger.log(`Loading ${this.name}`);

    // Make sure Connatix isn't already loaded
    if (!window[this.global]) {
      this.loadConnatixScript();
      this.appendPlayerScript();
      this.renderConnatixPlayer();
    }
  }

  /**
   * Determine whether this slot can be displayed on the page
   * @return {boolean}
   */
  canBeDisplayed() {
    return (
      super.canBeDisplayed() &&
      this.data.playerId &&
      this.data.scriptId &&
      (this.data.connatixPlayer || this.app.settings.connatixPlayer)
    );
  }

  /**
   * Load Connatix script
   * @return {undefined}
   */
  loadConnatixScript() {
    window[this.global] = {};
    window[this.global].cmd = [];

    const frame = document.createElement('iframe');
    frame.style.display = 'none';
    frame.addEventListener('load', () => {
      const frameBody = frame.contentWindow.document.body;

      const dependency = {
        url: this.scriptUrl,
        timeout: this.app.settings.connatixScriptTimeout || 3000,
        parentElement: frameBody,
      };

      new ScriptLoader()
        .load(dependency)
        .then(url => {
          Logger.log(`${this.name} script ${url} has loaded successfully`);
          this.rendered();
          this.markAsDisplayed();
        })
        .catch(error => {
          Logger.log(`Error loading ${this.name} script: ${error}`);
          Logger.log(`${this.data.name} has been collapsed.`);
          this.wrapper.collapseSlotWrapper();
          this.collapsed();
        });
    });

    this.element.appendChild(frame);
  }

  /**
   *  Append script that provides location of player
   * @return {object}
   */
  appendPlayerScript() {
    const playerScript = document.createElement('script');

    playerScript.id = this.data.scriptId;
    this.element.appendChild(playerScript);
  }

  /**
   *  Render Connatix Player
   * @return {undefined}
   */
  renderConnatixPlayer() {
    Promise.all(this.app.beforeAdsRequested).then(() => {
      const permutiveTargeting = window.localStorage.getItem('_pdfps');
      const {
        entry_type = '',
        entry_group = Array.isArray(entry_group) ? [''] : '',
        cts_keyword = [''],
        cts_keyword_list = [''],
        cts_iab_category = [''],
        affiliation = [''],
        page_type = Array.isArray(page_type) ? [''] : '',
        cts_present = Number(cts_present),
      } = this.app.variables;

      window[this.global].cmd.push(() => {
        // prettier-ignore
        let tagTargeting =[
        'basketball', 'baseball', 'ice-hockey',
        'nfl', 'college-football', 'soccer', 'mma'
      ].find(aff => affiliation.includes(aff)) || '';
        let trackerImg = new Image();
        trackerImg.src = `https://web.archive.org/web/20230321154937/https://capi.connatix.com/tr/si?token=${this.data.playerId}`;

        window[this.global]({
          playerId: this.data.playerId,
          settings: {
            advertising: {
              macros: {
                entry_type: entry_type[0] || entry_type,
                entry_group: entry_group,
                cts_keyword: cts_keyword,
                cts_keyword_list: cts_keyword_list,
                cts_iab_category: cts_iab_category,
                permutive: permutiveTargeting ? JSON.parse(permutiveTargeting) : [''],
                page_type: page_type,
              },
            },
            queryJsTargeting: {
              TagTargeting: tagTargeting,
            },
          },
        }).render(this.data.scriptId);
      });
    });
  }

  get playerType() {
    return this.data.connatixPlayer || this.app.settings.connatixPlayer || 'full';
  }

  get global() {
    return this.playerType === 'highlights' ? 'cnxps' : 'cnx';
  }

  get scriptUrl() {
    return this.playerType === 'highlights'
      ? 'https://web.archive.org/web/20230321154937/https://cd.connatix.com/connatix.playspace.js'
      : 'https://web.archive.org/web/20230321154937/https://cd.connatix.com/connatix.player.js';
  }

  static isSlotTypeFor(config) {
    return config.type === 'connatix';
  }
}
