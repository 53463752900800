import Logger from './logger';
import Phonograph from './phonograph';
import store from './store';

/**
 * Whether or not we send this data to the production Phonograph servers. We
 * will check this later on to see if we really want to do it or not.
 * @type {Boolean}
 */
let SEND_TO_PRODUCTION = false;

/**
 * Re-used to measure the length of auctions. With the initiation of an
 * auction, we get a unique id. But that is not attached to the end of an
 * action, so we must use this.
 * @type {Number}
 */
let AUCTION_START_TIME = 0;

/**
 * Default data that we will attach to every request to Phonograph.
 * @type {Object}
 */
let DEFAULT_DATA = {};

/**
 * The event types that we have defined in Prebid. These are actually set by
 * Prebid, we just have this to help us.
 * @type {Object}
 */
const EVENTS = {
  BID_REQUESTED: 'bidRequested',
  BID_TIMEOUT: 'bidTimeout',
  BID_RESPONSE: 'bidResponse',
  BID_WON: 'bidWon',
  AUCTION_INIT: 'auctionInit',
  AUCTION_END: 'auctionEnd',
  BID_ADJUSTMENT: 'bidAdjustment',
  SET_TARGETING: 'setTargeting',
  REQUEST_BIDS: 'requestBids',
};

/**
 * THe handlers for each event that we want to use. The actual functions are
 * kept below so that we have a better understanding of what "args" is for
 * each event.
 * @type {Object}
 */
const HANDLERS = {
  [EVENTS.BID_REQUESTED](args) {
    sendBidRequestToPhonograph(args);
  },
  [EVENTS.BID_RESPONSE](args) {
    sendBidResponseToPhonograph(args);
  },
  [EVENTS.BID_TIMEOUT](args) {
    sendBidTimeouts(args);
  },
  [EVENTS.BID_WON](args) {
    sendBidWonToPhonograph(args);
  },
  [EVENTS.AUCTION_INIT]() {
    AUCTION_START_TIME = Date.now();
  },
  [EVENTS.AUCTION_END](args) {
    sendAuctionComplete(args);
  },
};

/**
 * Allows us to make a single call to Phonograph, and optionally just log that
 * call to the console for local development.
 * @param  {Object} data The data that we will pass along to Phonograph.
 */
const sendToPhonograph = function(data) {
  for (var attribute in DEFAULT_DATA) {
    data[attribute] = DEFAULT_DATA[attribute];
  }

  Logger.log(`PHONOGRAPH DATA: ${JSON.stringify(data)}`);

  // Production data gets sent to Phonograph
  if (SEND_TO_PRODUCTION) {
    Phonograph.track('rev_prebid', data);
  }
};

/**
 * Converts dollars to cents.
 * @param  {Number} dollars Money.
 * @return {Number}         Money times 100.
 */
const convertToCents = function(dollars) {
  if (dollars) {
    return Math.floor(dollars * 100);
  }

  return 0;
};

/**
 * Sends the number of bids each bidder is making.
 * @param  {Object} bid The bid object
 */
const sendBidRequestToPhonograph = function(bid) {
  if (bid && bid.bidderCode) {
    sendToPhonograph({
      category: 'Requests',
      bidder: bid.bidderCode,
      enabled: 1,
      numberOfBids: Object.keys(bid.bids).length,
    });
  }
};

/**
 * Sends the bid response time and value to Phonograph when a successful bid
 * has been made.
 * @param  {Object} bid The bid object.
 */
const sendBidResponseToPhonograph = function(bid) {
  if (bid && bid.bidderCode) {
    var cpmCents = convertToCents(bid.cpm);

    if (bid.cpm > 0) {
      // Sends all calls that recieve a positive bid.
      sendToPhonograph({
        category: 'Bids',
        bidder: bid.bidderCode,
        value: cpmCents,
        time: bid.timeToRespond,
        unit: bid.adUnitCode,
      });
    }
  }
};

/**
 * When the bidder has timed out, we log that one too.
 * @param  {Array} timedOutBidders An array of bidders that timed out.
 */
const sendBidTimeouts = function(timedOutBidders) {
  store.set('timedout-bidders', timedOutBidders);
  timedOutBidders.forEach(bidderCode => {
    sendToPhonograph({
      category: 'Timeouts',
      bidder: bidderCode,
    });
  });
};

/**
 * Send a notification if one of our bidders actually won. Hurrraaaahhh!
 * @param  {Object} bid The bid object.
 */
const sendBidWonToPhonograph = function(bid) {
  var cpmCents = convertToCents(bid.cpm);

  sendToPhonograph({
    category: 'Wins',
    bidder: bid.bidderCode,
    value: cpmCents,
    unit: bid.adUnitCode,
  });
};

/**
 * Send a notification if we have ended an auction. Mark the start.
 */
const sendAuctionComplete = function() {
  const auctionEndTime = Date.now();

  store.set('auction-ended', auctionEndTime);
  if (AUCTION_START_TIME === 0) {
    return;
  }

  sendToPhonograph({
    category: 'AuctionLength',
    value: auctionEndTime - AUCTION_START_TIME,
  });
};

export default class PrebidEvents {
  /**
   * Create a new PrebidEvents service
   * @param {ConcertAds} app
   */
  constructor({ app }) {
    this.app = app;
    this.initialized = false;
  }

  /**
   * Initialize. Mostly will setup defaults, then it will add on events to pbjs
   * to tie into our analytics. Can only be run once.
   */
  initialize() {
    if (this.initialized) {
      return;
    }

    this.initialized = true;

    SEND_TO_PRODUCTION = this.app.settings.production || false;

    var phonographSample = this.app.settings.prebid.phonographSample || 100;

    if (SEND_TO_PRODUCTION && typeof phonographSample === 'number') {
      // phonographSample can be any positive number. It will choose a random
      // number (between 0 and 1) and multiply it by our sample. It is then
      // floored to an integer. We then proceed if the number generated is 1.
      // The effect is that we get a sampling of 1 out of our sample number
      // passed in. (e.g. phonographSample of 100 will result in 1/100 pages
      // being sent in).
      SEND_TO_PRODUCTION = Math.floor(Math.random() * phonographSample) + 1 === 1;
    }

    var message = SEND_TO_PRODUCTION ? 'is' : 'is not';

    Logger.log(`This pageload ${message} sampled for Phonograph`);

    this.setDefaultData();

    Object.keys(EVENTS).forEach(key => {
      var eventName = EVENTS[key];

      pbjs.onEvent(eventName, (args = {}) => {
        Logger.log(`PREBID: ${eventName} called.`, {
          prebid: true,
          eventName: eventName,
          args: args,
        });

        if (typeof HANDLERS[eventName] === 'function') {
          HANDLERS[eventName](args);
        }
      });
    });
  }

  /**
   * Sets the default data that we need from the outside world. All of these
   * should be set within the variables.
   */
  setDefaultData() {
    // If we already have all that we need, just return it.
    if ('device_type' in DEFAULT_DATA && 'network' in DEFAULT_DATA) {
      return DEFAULT_DATA;
    }

    if ('device_type' in this.app.variables) {
      DEFAULT_DATA.device_type = this.app.variables.device_type;
    }

    if ('network' in this.app.variables) {
      // Allow posibility that `network` is an array, or a value;
      // without inadvertently modifying `variables`
      DEFAULT_DATA.network = Array.isArray(this.app.variables.network)
        ? this.app.variables.network[0]
        : this.app.variables.network;
    }
  }
}
