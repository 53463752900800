import Events, { EventTypes } from '../lib/events';
import Logger from '../lib/logger';
import PluginBase from './base';

const CONCERT_CRESCENDO_CONTROL_KEY = 'ccc';

export default class ConcertCrescendoControl extends PluginBase {
  onInstall() {
    Logger.log('Installing plugin: ConcertCrescendoControl');

    Events.on(EventTypes.slotPublishedToAdServer, (name, data) => {
      this.setControlGroupStatus(data);
    });
  }

  setControlGroupStatus(data) {
    const slot = data.payload.slot;
    const slotName = data.payload.name;
    const control = (Math.random() < 0.05).toString();

    Logger.log(`Setting slot ${slotName} Crescendo control group status: ${control}`);
    slot.setTargeting(CONCERT_CRESCENDO_CONTROL_KEY, control);
  }
}
