import Events, { EventTypes } from '../../lib/events';
import { fetchJson } from '../../lib/fetch_json';
import store from '../../lib/store';
import settings from '../../lib/settings';

export const REMOTE_CONFIG_TIMEOUT_IN_MS = 5000;

export const REMOTE_CONFIG_STATUS = {
  CONNECTION_ERROR: Symbol('CONNECTION_ERROR'),
  CONNECTION_TIMEOUT: Symbol('CONNECTION_TIMEOUT'),
  SUCCESS: Symbol('SUCCESS'),
};

export class RemoteConfig {
  /**
   * Create a new instance of RemoteConfig to fetch config from a remote location.
   * @param {{ app: ChorusAds, configUrl: string }} args
   */
  constructor({ app, configUrl, timeoutInMs }) {
    this.app = app;
    this.configUrl = configUrl;
    this.hasFetched = false;
    this.timeoutInMs = parseInt(timeoutInMs) || REMOTE_CONFIG_TIMEOUT_IN_MS;
  }

  /**
   * Fetch remote config and return a merged version of existing settings along
   * with the remote config.
   *
   * @return {PromiseLike<object>} Computed settings
   */
  async toSettings() {
    const remoteConfig = await this.fetchRemoteConfig();
    this.hasFetched = true;

    return {
      ...this.app.settings,
      ...remoteConfig,
    };
  }

  fetchRemoteConfig() {
    this.fetchStartedAt = Date.now();
    this.app.logger.log(`Fetching remote config from ${this.configUrl}`);

    return new Promise(resolve => {
      fetchJson(this.configUrl)
        .then(({ data, xhr }) => this.onSuccess(resolve, data, xhr))
        .catch(error => this.onConnectionError(resolve, error));

      setTimeout(() => this.onConnectionTimeout(resolve), this.timeoutInMs);
    });
  }

  onSuccess(callback, config, xhr) {
    const latencyInMs = Date.now() - this.fetchStartedAt;

    recordGeoHeaders(xhr);

    if (!this.hasFetched) {
      config.remoteConfigStatus = REMOTE_CONFIG_STATUS.SUCCESS;

      this.app.logger.log(`Successfully fetched remote config from ${this.configUrl} in ${latencyInMs}ms`, {
        config,
      });

      Events.emit(EventTypes.remoteConfigLoaded, {
        configUrl: this.configUrl,
        latencyInMs,
      });

      callback(config);
    } else {
      this.app.logger.log(`Timed-out remote config finished loading in ${latencyInMs}ms`, {
        configUrl: this.configUrl,
      });

      Events.emit(EventTypes.remoteConfigTimeoutLoaded, {
        configUrl: this.configUrl,
        latencyInMs,
      });
    }
  }

  onConnectionError(callback, error) {
    this.app.logger.log(`Failed to fetch remote config from ${this.configUrl}: ${error.message}`, {
      error,
      level: 'error',
    });

    Events.emit(EventTypes.remoteConfigError, {
      configUrl: this.configUrl,
      error,
    });

    if (this.hasFetched) return;

    callback({
      remoteConfigStatus: REMOTE_CONFIG_STATUS.CONNECTION_ERROR,
    });
  }

  onConnectionTimeout(callback) {
    if (this.hasFetched) return;

    this.app.logger.log(
      `Failed to fetch remote config from ${this.configUrl} [Timed out after ${REMOTE_CONFIG_TIMEOUT_IN_MS}ms]`,
      {
        level: 'error',
      }
    );

    Events.emit(EventTypes.remoteConfigTimeout, {
      configUrl: this.configUrl,
    });

    callback({
      remoteConfigStatus: REMOTE_CONFIG_STATUS.CONNECTION_TIMEOUT,
    });
  }
}

export function remoteConfigMixin(ConcertAds) {
  /**
   * Load config JSON from a remote source.
   *
   * @param {string} configUrl
   * @param {number} timeoutInMs
   * @returns {PromiseLike}
   */
  ConcertAds.prototype.loadRemoteConfig = async function(configUrl, options = {}) {
    if (!configUrl || typeof configUrl !== 'string') {
      throw new TypeError('loadRemoteConfig requires a configUrl (string) argument');
    }

    const { timeoutInMs } = options;
    const config = new RemoteConfig({ app: this, configUrl, timeoutInMs });

    const newSettings = await config.toSettings();

    // We have a custom merge strategy in config.toSettings(),
    // so we want to overwrite our settings to this new base.
    settings.set(newSettings);
  };
}

/**
 * Grab geolocation headers and store them.
 *
 * @param {XMLHttpRequest} xhr Request
 */
function recordGeoHeaders(xhr) {
  const region = xhr.getResponseHeader('geo-region');
  if (!region) return;
  store.set('geo-region', region);
}
