import BidManager from './bid_manager';
import Classifier from './classifier';
import { forceSafeframe, safeframeSettings } from './safeframe_config';
import { setUpInitialVariables } from '../instance/mixins/variables';
import Events, { EventTypes } from './events';
import Logger from './logger';

/**
 * Instantiate and run setup methods that interact with the app's provided
 * settings.
 */
export function bootDependenciesOfSettings(app) {
  // This contains promises that must be satisfied before the
  // ads/content can be inserted. If we get new settings, we
  // need to clear out this queue.
  app.beforeAdsRequested = [];
  setUpDfp(app);
  setUpInitialVariables(app);
  setUpClassifier(app);
  Events.emit(EventTypes.settingsAvailable, {});
  if (app.settings.prebid.biddingEnabled) {
    app.bidManager = new BidManager({ app });
    Events.emit(EventTypes.bidManagerCreated);
  }
}

/**
 * Set up the Classifier class, and ensure ads are blocked from requesting
 * until the Classifier is finished.
 *
 * @param {ConcertAds} app
 */

function setUpClassifier(app) {
  app.classifier = new Classifier({ app });
  app.beforeAdsRequested.push(app.classifier.blockUntilComplete());
}

/**
 * Initialize googletag library
 */
function setUpDfp(app) {
  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];

  window.googletag.cmd.push(function() {
    googletag.pubads().setForceSafeFrame(forceSafeframe({ settings: app.settings }));
    googletag.pubads().setSafeFrameConfig(safeframeSettings({ settings: app.settings }));

    // Using this method splits apart the request and showing of the ad
    googletag.pubads().disableInitialLoad();

    googletag.enableServices();

    googletag.pubads().addEventListener('slotRenderEnded', evt => {
      var concertSlot = app.slots.getByGoogleSlot(evt.slot);
      if (concertSlot) {
        concertSlot.rendered(evt);
      } else {
        Logger.log('Unable to handle event for unknown slot');
      }
    });
    googletag.pubads().addEventListener('slotOnload', evt => {
      var concertSlot = app.slots.getByGoogleSlot(evt.slot);
      if (concertSlot) {
        concertSlot.loaded(evt);
      } else {
        Logger.log('Unable to handle event for unknown slot');
      }
    });
    googletag.pubads().addEventListener('impressionViewable', evt => {
      var concertSlot = app.slots.getByGoogleSlot(evt.slot);
      if (concertSlot) {
        concertSlot.viewed(evt);
      } else {
        Logger.log('Unable to handle event for unknown slot');
      }
    });
  });
}
