import Logger from './logger';
import { deferUntilPageVisible } from './page_visibility';

/**
 * Store the observer in a variable for use in the singleton
 * @type {IntersectionObserver}
 */
let _observer;

export default class ObserverIntersection {
  /**
   * Watch a single slot
   * @param  {Slot} slot
   * @return {undefined}
   */
  static watchSlot(slot) {
    var target = document.getElementById(slot.id);

    // This should ONLY fail during tests
    if (!target) {
      Logger.log(`Could not watch ${slot.name} because it is not in the DOM`);
      return;
    }

    boundsObserver(slot.app.settings.observerThreshold).observe(target);
    Logger.log(`Starting to watch ${slot.name}`);
  }
}

/**
 * Gets the ad observer object since this is a static method
 * @param {observerThreshold}
 * @return {Observer}
 */
function boundsObserver(observerThreshold = '100px 0px 200px 0px') {
  if (!_observer) {
    _observer = new IntersectionObserver(
      (entries, observer) => {
        Logger.log(`Observer is being called`, { entries: entries });

        entries.forEach(function(entry) {
          // IntersectionObserver returns all the entries for every observation
          // But we only want to show ones that are intersecting
          // I AM SORRY:`isIntersecting` is something safari/webkit supports :(((
          if (entry.intersectionRatio > 0.0 || entry.isIntersecting) {
            var slot = entry.target.__slot__;

            // Stop watching the slot, and trigger the callback.
            observer.unobserve(entry.target);
            const settingsDontAllowForPageVisibility = !slot.app.settings.canUsePageVisibilityAPI;

            deferUntilPageVisible(() => slot.observed(), {
              runImmediately: settingsDontAllowForPageVisibility,
            });
          }
        });
      },
      {
        /**
         * This represents the options that we will pass along to IntersectionObserver
         * when we set it up.
         *    rootMargin: The margin that the IntersectionObserver will consider within
         *                the viewport. In this case, it is 100px above the visibile
         *                viewport, and 200px below the visible viewport will be
         *                considered the full viewport in regards to the observer.
         *                default value from settings: 100px 0px 200px 0px
         */
        rootMargin: observerThreshold,
      }
    );
  }

  return _observer;
}
