const nativeFieldKeys = {
  'Native Ad Linkset Link': ['title', 'logo', 'byline'],
  'Native Ad Content Link': ['title', 'byline', 'logo', 'thumbnail', 'eyebrow', 'description'],
  'Native Ad Latest': ['thumbnail', 'sponsored_logo', 'title', 'description', 'preamble'],
  'Native Ad Linkset 2.0': ['title', 'logo', 'byline'],
  'Native Ad Content Link 2.0': ['title', 'byline', 'logo', 'thumbnail', 'eyebrow', 'description'],
  'Native Ad Latest 2.0': ['thumbnail', 'sponsored_logo', 'title', 'description', 'preamble'],
};

export default {
  listenForHymnalRenderEvents(app) {
    document.addEventListener('hymnal_render', e => {
      if (!e.detail) {
        return false;
      }
      var ad = e.detail.ad;

      if (ad.type.includes('Native Ad')) {
        if (app.settings.repository === 'duet' && ad.design_template !== 'Native Ad Mobile') {
          this.renderAdByReplacingContent(ad, nativeFieldKeys[ad.design_template]);
        } else if (ad.design_template && ad.design_template.includes('Native Ad Latest')) {
          this.renderLatestNative(ad);
        } else if (ad.design_template && ad.design_template.includes('Native Ad Content Link')) {
          this.renderContentLink(ad);
        }

        if (ad.trackElement) {
          ad.trackElement(ad._element[0]);
        }
      }
    });
  },

  renderAdByReplacingContent(ad = null, fieldKeys) {
    // Unclear if we still need this
    if (!('remove' in Element.prototype)) {
      Element.prototype.remove = function() {
        if (this.parentNode) {
          this.parentNode.removeChild(this);
        }
      };
    }

    const fields = ad.fields;
    const containerSelector = adDataSelector('container');
    const adSlot = findParent(ad._element[0], containerSelector);
    const logoKey = findKey(fieldKeys, 'logo');
    const thumbnailKey = findKey(fieldKeys, 'thumbnail');

    removeElemIfPresent(adSlot, containerSelector);
    removeElemIfPresent(adSlot, 'svg');

    [...adSlot.querySelectorAll('a')].forEach(a => a.setAttribute('href', ad.clickthroughUrl));

    const textFieldKeys = fieldKeys.filter(key => !key.includes('logo') && !key.includes('thumbnail'));

    textFieldKeys.forEach(fieldKey => {
      const removeIfBlank = fieldKey === 'eyebrow' ? true : false;
      const fieldValue =
        fieldKey === 'byline'
          ? `Advertiser Content From ${fields[fieldKey] ? fields[fieldKey] : ''}`
          : fields[fieldKey];
      applyText(adSlot, adDataSelector(fieldKey), fieldValue, removeIfBlank);
    });

    if (logoKey) {
      updateLogo(fields, logoKey);
    }

    if (thumbnailKey) {
      updateThumbnail(fields, thumbnailKey);
    }

    makeSponsorClickable(ad);
    adSlot.classList.remove('hidden');
  },

  renderContentLink(ad = null) {
    // omg IE i hate you
    if (!('remove' in Element.prototype)) {
      Element.prototype.remove = function() {
        if (this.parentNode) {
          this.parentNode.removeChild(this);
        }
      };
    }

    var adSlot = findParent(ad._element[0], adDataSelector('container'));
    var fields = ad.fields;

    removeElemIfPresent(adSlot, adDataSelector('container'));
    removeElemIfPresent(adSlot, 'svg');

    [...adSlot.querySelectorAll('a')].forEach(a => a.setAttribute('href', ad.clickthroughUrl));

    var byline = (fields.preamble || 'Advertiser Content From') + ' ' + fields.byline;
    applyText(adSlot, adDataSelector('byline'), byline);
    applyText(adSlot, adDataSelector('description'), fields.description);
    applyText(adSlot, adDataSelector('eyebrow'), fields.eyebrow || '', true);
    applyText(adSlot, adDataSelector('headline'), fields.title);

    [...adSlot.querySelectorAll(adDataSelector('preamble_bg'))].forEach(el => {
      el.style.backgroundColor = fields.brand_color;
    });

    [...adSlot.querySelectorAll(adDataSelector('thumbnail'))].forEach(thumbnail => {
      thumbnail.classList.remove('lazy-image', 'vox-lazy-load', 'lazy-loaded');
      thumbnail.src = fields.thumbnail;
      thumbnail.setAttribute('data-original', fields.thumbnail);
      thumbnail.alt = fields.description;
      thumbnail.style.cssText =
        'background-image:url(' + fields.thumbnail + '); background-size: cover; background-repeat:no-repeat;';
    });

    [...adSlot.querySelectorAll(adDataSelector('logo'))].forEach(logo => {
      logo.src = fields.logo;
      logo.alt = fields.description;
    });

    makeSponsorClickable(ad);
  },

  // The Latest Native is served in a slot below the list of items
  // this code places the slot in the list at the defined position
  // or at the very end if there's no position/ fewer items than the position calls for
  renderLatestNative(ad) {
    var adSlot = ad._element[0];
    // convert string to integer
    var location = parseInt(ad.fields.position, 10);
    location = isNaN(location) ? 5 : location;

    // [class*=c-rock-list] looks for any element that has the prefix of .c-rock-list, doing this to support other networks having different selectors but have the prefix of "c-rock-list"
    var container = findParent(adSlot, '[class*=c-rock-list]');
    var list = container.querySelector('[class*=list]');
    var items = list.querySelectorAll('li');

    // it offends me to put a div in an ol element so i'm wrapping this in a li
    var wrapper = document.createElement('li');
    wrapper.appendChild(adSlot);

    // if we can put it in the specified location, do so, otherwise put it at the end
    if (items.length > location) {
      list.insertBefore(wrapper, items[location]);
      items[location].remove();
    } else {
      // if that doesn't exist
      list.appendChild(wrapper);
    }
  },
};

function adDataSelector(id) {
  return `[data-native-ad-id="${id}"]`;
}

// helper function that walks through parent elements until a selector is met
function findParent(node, selector) {
  var el = false;
  var parent = node.parentNode;
  while (!el) {
    if (parent && parent.matches(selector)) {
      el = parent;
    } else {
      parent = parent.parentNode;
    }
  }
  return el;
}

function removeElemIfPresent(adSlot, selector) {
  [...adSlot.querySelectorAll(selector)].forEach(el => el.remove());
}

function applyText(adSlot, selector, data, removeIfBlank) {
  [...adSlot.querySelectorAll(selector)].forEach(el => {
    el.innerText = data;
    if (!!removeIfBlank && data.trim().length == 0) el.remove();
  });
}

// Pass in an ad and a selector if you want to override
// the default selector of `.native-ad-sponsorship`
function makeSponsorClickable(ad, selector) {
  selector = selector || '.native-ad-sponsorship,.native-ad-byline';
  let adElement = ad._element[0];
  if (adElement) {
    [...adElement.querySelectorAll(selector)].forEach(el => {
      el.style.cursor = 'pointer';
      el.addEventListener('click', function() {
        window.location = ad.clickthroughUrl;
      });
    });
  }
}

function updateLogo(fields, key) {
  [...document.querySelectorAll(adDataSelector(key))].forEach(el => {
    if (fields[key] && fields[key] !== 'null') {
      el.src = fields[key];
    }
    el.alt = fields.description;
  });
}

function updateThumbnail(fields, key) {
  [...document.querySelectorAll(adDataSelector(key))].forEach(el => {
    el.setAttribute('data-original', fields[key]);
    if (el.tagName === 'IMG') {
      if (fields[key] && fields[key] !== 'null') {
        el.src = fields[key];
      }
      el.alt = fields.description;
    } else {
      el.style.cssText = `background-image:url('${fields[key]}'); background-size: cover; background-repeat:no-repeat;`;
    }
  });
}

function findKey(keys, text) {
  return keys.find(key => key.includes(text));
}
