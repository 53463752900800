import Logger from './logger';
export const MOAT_TIMEOUT = 1000;

export class MoatHelper {
  constructor() {
    this.moatLoaded = false;
    this.queue = [];

    // 1. Moat will fire this method when it is loaded.
    window.moatYieldReady = () => this.loaded();

    // 2. In case Moat is too slow, we'll set a timeout to render slots anyway.
    window.setTimeout(() => this.loaded(), MOAT_TIMEOUT);
  }

  /**
   * Fire this method when Moat is ready, or when we're tired of waiting.
   */
  loaded() {
    // No racing, please.
    if (this.moatLoaded) return;

    this.moatLoaded = true;

    // Add targeting to all available slots
    this.addTargeting();

    // Go through the queue and fire each method.
    this.queue.forEach(fn => fn());
    this.queue = [];
  }

  /**
   * Fire a method when Moat is loaded.
   */
  whenReady(fn) {
    if (this.moatLoaded) {
      fn();
    } else {
      this.queue.push(fn);
    }
  }

  /**
   * Check if slot data is available from Moat
   *
   * @returns {Boolean}
   */
  slotDataAvailable() {
    return (
      typeof window.moatPrebidApi !== 'undefined' &&
      typeof window.moatPrebidApi.slotDataAvailable === 'function' &&
      window.moatPrebidApi.slotDataAvailable()
    );
  }

  /**
   * Add Moat targeting to all slots, if it's available
   */
  addTargeting() {
    if (this.slotDataAvailable()) {
      if (Logger.debuggerEnabled) {
        window.moatPrebidApi.enableLogging();
      }
      window.moatPrebidApi.setMoatTargetingForAllSlots();
    }
  }
}

export default new MoatHelper();
