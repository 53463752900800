import { containsPii, clone, getWebView, getDeviceType } from '../../lib/utils';
import Logger from '../../lib/logger';

export function ensureVariableIsString(variable) {
  /**
   * Ensures variables are converted to strings for third-party services that require this
   * (GAM requires this for valid targeting key-values)
   * @param  {String} variable Targeting parameter value or list of values.
   */
  return Array.isArray(variable) ? variable.map(v => String(v)) : String(variable);
}

export function variablesMixin(ConcertAds) {
  /**
   * Publishes custom variables to third-party services (Google)
   * @param  {String} key      Targeting parameter key.
   * @param  {String} variable Targeting parameter value or list of values.
   */
  ConcertAds.prototype.addVariable = function(key, variable) {
    // Avoid setting variables that look like PII
    // Currently checks for things that look like email addresses.
    // See: https://support.google.com/adsense/topic/6162392
    if (containsPii(variable)) return false;

    this.variables[key] = variable;

    // Send the variable to Google. Targeting FTW.
    googletag.cmd.push(function() {
      googletag.pubads().setTargeting(key, ensureVariableIsString(variable));
      Logger.log(`Adding ${key} with value: ${ensureVariableIsString(variable)}`);
    });

    // For tests
    return true;
  };

  /**
   * Getter for all available ConcertAds variables
   * @param  {String} key      Targeting parameter key to get.
   * @return {String}          Returns value of key or false
   */
  ConcertAds.prototype.getVariable = function(key) {
    return this.getAllVariables()[key];
  };

  /**
   * Getter for all available ConcertAds variables
   * @param  {String} key      Targeting parameter key to get.
   * @return {String}          Returns value of key or false
   */
  ConcertAds.prototype.getAllVariables = function() {
    return clone(this.variables);
  };
}

export function setUpInitialVariables(app) {
  app.variables = {};

  /**
   * Set "webview" variable.
   */
  let webview = getWebView(window.navigator.userAgent);
  if (webview) {
    app.addVariable('webview', webview);
  }

  /**
   * Set "device_type" variable.
   */
  app.addVariable('device_type', getDeviceType());

  /**
   * Set any variables passed as constructor options, which take priority
   * over derived variables.
   */
  let constructorVars = app.settings.dfpVariables || {};
  Object.keys(constructorVars).forEach(key => {
    app.addVariable(key, constructorVars[key]);
  });
}
