import Logger from '../lib/logger';

export default {
  localStorage(options = {}) {
    return new Promise(resolve => {
      const { storageKey } = options;

      if (!storageKey) {
        Logger.error(`Skipping classifier localStorage because it is missing a 'storageKey'`);
        return resolve(false);
      }

      const value = localStorage.getItem(storageKey);

      if (value === null) {
        Logger.log(`Skipping classifier localStorage because item ${storageKey} is empty`);
        resolve(false);
      }

      if (value[0] === '{' || value[0] === '[') {
        try {
          return resolve(JSON.parse(value));
        } catch (e) {
          return resolve(value);
        }
      }

      resolve(value);
    });
  },
};
