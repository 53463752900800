import Logger from '../lib/logger';
import { fetchJson } from '../lib/fetch_json';
import { mergeByMakingArraysOfValuesWithTheSameKey } from '../lib/utils';

/**
 * @file The classifiers we use to determine contents
 */

export default {
  /**
   * Search inside of a selector to find matching string based on a regex
   *
   * @param  {{ selector: string, matching: RegExp }} options
   * @return {Promise<string>} Resolves with the string of matched words once this classifier is complete.
   */
  selectorContentsContains: function(options = {}) {
    const uniqueValueFilter = (v, i, all) => {
      return all.indexOf(v) === i;
    };

    return new Promise(resolve => {
      const selector = options.selector || '';
      const matching = options.matching || false;

      if (!selector || !matching) {
        Logger.error(
          `Skipping classifier 'selectorContentsContains' because it is missing 'selector' or 'matching' param`,
          { options: options }
        );
        resolve(false);
      } else {
        const selectorText = [...this.dom.querySelectorAll(selector)].reduce((fullString, el) => {
          return fullString + ' ' + (el.innerText || el.textContent);
        }, '');

        const match = selectorText.match(matching);

        if (!!match && match.length) {
          resolve(match.filter(uniqueValueFilter).join(' '));
        } else {
          resolve(false);
        }
      }
    });
  },

  /**
   * Add a constant value to the classifier variables
   *
   * @param  {{ value: string|string[] }} options
   * @return {Promise<string|string[]>}
   */
  constantValue: function(options = {}) {
    return new Promise(resolve => {
      resolve(options.value || false);
    });
  },

  /**
   * Fetch targeting key/values from a Motif's remote endpoint. Automatically
   * appends the current URL as a Base64-encoded hash.
   *
   * @param  {{ endpoint: string }} options
   * @return {Promise<{[key: string]: string|string[]}>} Returns an object with targeting key/values.
   */
  loadMotifTargeting(options = {}, url = null) {
    return new Promise(resolve => {
      if (!options.endpoint) {
        Logger.error(`An endpoint must be passed to the loadMotifTargeting classifier`);

        return resolve(false);
      }

      const canonicalElement = document.querySelector('link[rel="canonical"][href]');
      const canonicalURL = canonicalElement ? canonicalElement.href : null;
      url = url || canonicalURL || window.location.href;

      if (!isValidContentUrl(url)) {
        return resolve(false);
      }

      const encodedUrl = base64SafeEncodeUrl(stripHashFromUrl(url));

      const MS_IN_ONE_DAY = 1000 * 60 * 60 * 24;

      // For these purposes, say "month" == "30 days"
      const TIME_SINCE_BUCKETS = [
        [1, 'under_1_day'],
        [3, 'under_3_days'],
        [7, 'under_1_week'],
        [30, 'under_1_month'],
        [60, 'under_2_months'],
        [90, 'under_3_months'],
        [120, 'under_4_months'],
        [150, 'under_5_months'],
        [180, 'under_6_months'],
        [210, 'under_7_months'],
        [240, 'under_8_months'],
        [270, 'under_9_months'],
        [300, 'under_10_months'],
        [330, 'under_11_months'],
        [360, 'under_12_months'],
        [10e10, 'over_12_months'],
      ];

      fetchJson(`${removeTrailingSlash(options.endpoint)}/${encodedUrl}.json`)
        .then(({ data }) => {
          let timestamp, daysSince, bucket;

          function firstElementLargerThan(target) {
            return val => {
              return val[0] > target;
            };
          }

          if (data.cts_keyword_classification_completed_at) {
            timestamp = data.cts_keyword_classification_completed_at;
            daysSince = ((options.mockedDate || new Date()) - new Date(timestamp)) / MS_IN_ONE_DAY;
            bucket = TIME_SINCE_BUCKETS.find(firstElementLargerThan(daysSince));
            if (bucket) {
              data.cts_keyword_age = bucket[1];
              delete data.cts_keyword_classification_completed_at;
            }
          }

          resolve(data);
        })
        .catch(e => {
          Logger.error(`loadMotifTargeting failed to fetch targeting from ${options.endpoint}`, {
            error: e.message,
          });
          resolve(false);
        });
    });
  },

  clientSideKeywordScan(options = {}) {
    return new Promise(resolve => {
      if (!options.endpoint) {
        Logger.error(`An endpoint must be passed to the clientSideKeywordScan classifier`);

        return resolve(false);
      }

      fetchJson(options.endpoint)
        .then(({ data }) => {
          let targetingToAdd = {};

          if (data && Array.isArray(data)) {
            const documentBody = this.dom.body.innerText || this.dom.body.textContent;
            const textToScan = [documentBody, this.dom.title, window.location.href].join(' ');

            data.forEach(list => {
              if (list.scan && Array.isArray(list.scan)) {
                // joining all of the phrases with a pipe,
                // forces regex into a giant "OR" condition
                const phrases = new RegExp(list.scan.join('|'), 'i');
                const matched = phrases.test(textToScan);
                if (matched && list.match) {
                  targetingToAdd = mergeByMakingArraysOfValuesWithTheSameKey(targetingToAdd, list.match);
                }

                if (!matched && list.noMatch) {
                  targetingToAdd = mergeByMakingArraysOfValuesWithTheSameKey(targetingToAdd, list.noMatch);
                }
              }
            });
          }
          resolve(targetingToAdd);
        })
        .catch(e => {
          Logger.error(`clientSideKeywordScan failed to fetch targeting from ${options.endpoint}`, {
            error: e.message,
          });
          resolve(false);
        });
    });
  },
};

/**
 * Strip a trailing slash from a URL.
 *
 * @param {string} url
 */
function removeTrailingSlash(url) {
  return url.replace(/\/+$/, '');
}

/**
 * Strip the hash param from a URL.
 *
 * @param {string} url
 */
function stripHashFromUrl(url) {
  return url.split('#')[0];
}

/**
 * Return a URL-safe version of a Base64-encoded string.
 * Parsed at Motif's endpoint.
 *
 * @see https://ruby-doc.org/stdlib-2.1.3/libdoc/base64/rdoc/Base64.html#method-i-urlsafe_encode64
 *
 * @param {string} url
 */
export function base64SafeEncodeUrl(url) {
  return btoa(url)
    .replace(/\//g, '_')
    .replace(/\+/g, '-');
}

/**
 * Ensure URL is a valid http(s) URL
 * @param {string} url
 */
function isValidContentUrl(url) {
  return /^https?/.test(url) && !/^https?:\/\/(127\.0\.0\.1|0\.0\.0\.0|localhost)/.test(url);
}
