/* eslint-disable no-prototype-builtins */
import { distanceFromBottom } from './utils';
import Logger from './logger';
import ObserverIntersection from './observer_intersection';
import Events, { EventTypes } from './events';
import Filters from '../filters/ad_insertion';
import SlotWrapper from './slot_wrapper';

let uniqueId = 0;

export default class Slot {
  /**
   * Create new Slot
   * @param {object} data
   */
  constructor(app = {}, data = {}, neighbor) {
    this.app = app;
    this.data = data;
    this.neighbor = neighbor;

    this.configName = generateName.call(this);
    this.name = generateInstanceName.call(this);
    this.timesRendered = 0;

    this.applyDefaults();

    // We also want to store the ID. Generally, we have just the name of the slot,
    // but in case we want to change that, we can.
    this.id = this.data.id ? this.data.id : this.name;
  }

  /**
   * We take anything passed on as an extra option, which could include things
   * like this slot is not prebid eligible, and add it into the data we want
   * to store in the recorded slots.
   * @return {object} Official slot object
   */
  applyDefaults({ refresh = false, state = {} } = {}) {
    // Mark keys to exclude from data
    const exclude = ['name'];

    // These defaults live directly on the Slot object
    let slotDefaults = {
      index: 0,
      context: {},
    };

    if (!refresh) {
      slotDefaults.element = false;
    }

    for (var def in slotDefaults) {
      if (slotDefaults.hasOwnProperty(def)) this[def] = slotDefaults[def];
    }

    for (var d in this.data) {
      if (exclude.indexOf(d) > -1) continue;
      if (this.data.hasOwnProperty(d)) this[d] = this.data[d];
    }

    // Now, we add in the attributes that under no circumstances do we want to
    // be able to override as we register this slot.
    let defaultState = {
      collapsed: false,
      defined: false,
      displayEligible: false,
      watcherEligible: this.data.watcherEligible === false ? false : true,
      displayed: false,
      renderTime: false,
    };

    this._state = typeof state === 'object' ? { ...state, ...defaultState } : defaultState;

    return this;
  }

  /**
   * Determine whether this slot can be displayed on the page
   * @return {boolean}
   */
  canBeDisplayed() {
    var context = this.context || {};
    var browserWidthForSlot = context.browser_width || {};

    // If the requested context device type is not null— and the device type
    // requested by the ad is not what is— then we will not show the ad.
    if (context.device_type && context.device_type.indexOf(this.app.variables.device_type) < 0) {
      Logger.log('Not available for display: device');
      return false;
    }

    // More complex. If the requested browser width minimum is greater than the
    // current with (smaller than what we want) OR if we have a requested max
    // width (not zero) and our max width is less than the current browser
    // width, we do not show the ad.
    if (
      browserWidthForSlot.min > window.innerWidth ||
      (browserWidthForSlot.max !== 0 && browserWidthForSlot.max < window.innerWidth)
    ) {
      Logger.log('Not available for display: browser width');
      return false;
    }

    // Check to see if the element in question exists, and if
    // it does not, we skip it entirely.
    if (this.app.dom && !this.app.dom.querySelector(`#${this.id}`)) {
      Logger.log('Not available for display: element');
      return false;
    }

    if (this.app.settings.isPreview) {
      Logger.log('Not available for display: preview');
      return false;
    }

    return true;
  }

  /**
   * Destroy this slot's wrapper and DOM element, and remove it from DFP.
   */
  destroy() {
    this.wrapper.destroy();
    Logger.log(`Slot ${this.name} was destroyed`);
  }

  /**
   * Method that runs once the slot is inserted into the DOM
   * @return {undefined}
   */
  inserted() {
    if (!this.canBeDisplayed()) return;

    const elem = this.getElement();

    if (elem) {
      elem.dispatchEvent(
        new CustomEvent('concertAdRegistered', { detail: { slotElement: elem }, bubbles: true, composed: true })
      );
    }

    Events.emit(EventTypes.slotInserted, { slotName: this.name });

    this.setDefined();

    this.isEager() ? this.render() : this.observe();
  }

  /**
   * Callback run when the observer marks the slot as visible.
   * @return {undefined}
   */
  observed() {
    Events.emit(EventTypes.slotObserved, { slotName: this.name });

    this.render();
    Logger.log(`${this.name} scrolling into view and displaying`);
    this._state.displayEligible = false;
  }

  /**
   * Callback run when slot is rendered
   * @return {undefined}
   */
  rendered() {
    const elem = this.getElement();
    this._state.rendered = true;
    const renderLocation = distanceFromBottom(elem);

    this._state.renderTime = Date.now() - this._state.renderTime;

    Events.emit(EventTypes.adRendered, {
      slotName: this.name,
      renderLocation,
    });
    elem.dispatchEvent(
      new CustomEvent('concertAdRendered', { detail: { slotElement: elem }, bubbles: true, composed: true })
    );
    Logger.log(`Rendering slot: ${this.name}`);
  }

  /**
   * Callback run when the slot is starting to be collapsed
   * @return {undefined}
   */
  collapsed() {
    this._state.collapsed = true;
    Events.emit(EventTypes.slotCollapsed, { slotName: this.name });
  }

  /**
   * Should slot be eager loaded?
   * @return {Boolean}
   */
  isEager() {
    return !this.isWatcherEligible() && this.isDisplayEligible() && !this.isDisplayed();
  }

  /**
   * Does this slot have the given size? Most sizes are a [w, h] array
   * but we have at least one size that is a string, the `fluid` size.
   * @param  {Array or String}  size
   * @return {Boolean}
   */
  hasSize(size) {
    return this.sizes.some(s => {
      return Array.isArray(s) && Array.isArray(size)
        ? s
            .slice()
            .sort()
            .join(',') ===
            size
              .slice()
              .sort()
              .join(',')
        : s === size;
    });
  }

  /**
   * Get the slot element by its ID. If element is not cached, finds and caches element.
   * @return {HTML element}
   */
  getElement() {
    if (!this.element) {
      this.element = document.getElementById(this.id);
    }
    return this.element;
  }

  /**
   * Add this slot to the Observer, which will trigger the #observed callback
   * on this slot when observed.
   */
  observe() {
    ObserverIntersection.watchSlot(this);
  }

  isCollapsed() {
    return this._state.collapsed;
  }

  isDefined() {
    return this._state.defined;
  }

  isDisplayEligible() {
    return this._state.displayEligible;
  }

  isDisplayed() {
    return this._state.displayed;
  }

  isWatcherEligible() {
    return this._state.watcherEligible;
  }

  shouldTrackRenderedEvent() {
    return false;
  }

  shouldTrackScrollVelocity() {
    return false;
  }

  /**
   * Set a slot as defined within DFP. While this is only called internally in this
   * class, it is also used during tests.
   *
   * @return {undefined}
   */
  setDefined() {
    this._state.displayEligible = true;
    this._state.defined = true;
  }

  /**
   * Mark this slot as displayed. Called from Ad.
   * @return {undefined}
   */
  markAsDisplayed() {
    this._state.displayed = true;
  }

  /**
   * Show content in this slot
   * @return {undefined}
   */
  render() {
    return this.isDisplayEligible() && !this.isDisplayed();
  }

  /**
   * Get the holdSize for a slot, falling back to preview if given
   * @return {mixed} Array [100,100] or null
   */
  getHoldSize() {
    if (this.holdSize) {
      return this.holdSize;
    }

    if (this.app.settings.isPreview && this.previewHoldSize) {
      return this.previewHoldSize;
    }

    return null;
  }

  /**
   * Get slot wrapper
   * @param  {Slot}     slot       Instance of Slot
   * @param  {object}   config     Config
   * @param  {boolean}  existing   Whether we're creating from an existing DOM el
   * @return {SlotWrapper}         SlotWrapper instance
   */
  getSlotWrapper({ slot, config, existing }) {
    return new SlotWrapper({ slot, config, existing });
  }

  /**
   * Determine if a slot will pass a filter it has specified (if any)
   * @param  {object} config Slot config
   * @param  {Node} el       Neighbor element
   * @param {object} settings App settings
   * @return {Boolean}       True if passes, false if not
   */
  static filterSlot(config, el, settings) {
    if (!config.filters) {
      return true;
    }

    var filterName, filterArg, filter;
    var filters = Object.keys(config.filters);
    for (var i = 0; i < filters.length; i++) {
      filterName = filters[i];
      filterArg = config.filters[filterName];
      filter = Filters[filterName];

      if (!filter) {
        continue;
      }

      if (!filter(el, filterArg, settings)) {
        Logger.log(`${config.name} didn't pass filter: ${filterName}`);
        return false;
      }
    }

    return true;
  }

  static shouldSkip() {
    return false;
  }
}

/**
 * Generate a new slot name
 * @return {string}
 */
function generateName() {
  return this.data.name && this.data.name.length ? this.data.name : generateDynamicName();
}

/**
 * Create the instance name of the slot, incremented with an integer if it's
 * one of many of the same type.
 *
 * Since we key by name (and DFP does as well) we need to ensure that the
 * name is actually unique. We also use the name as-is for the
 * configuration of other things, such as the Prebid data. To ensure we do
 * not duplicate any slots, we will add some logic that will test for the name.
 * @return {string}
 */
function generateInstanceName() {
  if (!this.app.slotNames.exists(this.configName)) {
    this.app.slotNames.increment(this.configName);
    return this.configName;
  }

  let slotName = this.configName;
  let count = this.app.slotNames.getCount(this.configName);
  slotName = `${slotName}_${count}`;

  this.app.slotNames.increment(this.configName);

  return slotName;
}

/**
 * Generates a dynamic name for slots that don't have a name specified
 * @return {string}
 */
function generateDynamicName() {
  return `v-dynamic-slot--${++uniqueId}`;
}
