import Slot from '../slot';
import Logger from '../logger';
import ScriptLoader from '../script_loader';

export default class RemixedSlot extends Slot {
  constructor(app, data) {
    super(app, data);
  }

  /**
   * Show an ad in this slot
   * @return {undefined}
   */
  render() {
    if (!super.render()) return;

    Logger.log(`Rendering ${this.name}`);

    this.loadScript();
  }

  /**
   * Determine whether this slot can be displayed on the page
   * @return {boolean}
   */
  canBeDisplayed() {
    return super.canBeDisplayed() && this.app.settings.remix.url && this.app.settings.remix.scriptId;
  }

  /**
   * Load Remixed script
   * @return {undefined}
   */
  loadScript() {
    const dependency = {
      url: `https://web.archive.org/web/20230321154937/https://tags.remixd.com/player/v5/index.js`,
      customAttributes: {
        id: this.app.settings.remix.scriptId,
        charset: 'utf-8',
      },
    };

    new ScriptLoader()
      .load(dependency)
      .then(url => {
        Logger.log(`${this.name} script ${url} has loaded successfully`);
        this.rendered();
        this.markAsDisplayed();
      })
      .catch(error => {
        Logger.log(`Error loading ${this.name} script: ${error}`);
      });
  }

  static isSlotTypeFor(config) {
    return config.type === 'remixd';
  }
}
