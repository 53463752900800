export function fetchJson(url, { method = 'GET', requestGeo = false, data, contentType } = {}) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onerror = function() {
      reject(new TypeError('Network request failed'));
    };

    xhr.ontimeout = function() {
      reject(new TypeError('Network request failed'));
    };

    xhr.onabort = function() {
      reject(new DOMException('Aborted', 'AbortError'));
    };

    xhr.onload = function() {
      if (xhr.status !== 200 && xhr.status !== 304) {
        return reject(new Error(`${xhr.status} (${xhr.statusText})`));
      }

      const body = 'response' in xhr ? xhr.response : xhr.responseText;
      try {
        resolve({ data: JSON.parse(body), xhr });
      } catch (e) {
        reject(e.message);
      }
    };

    xhr.open(method, url, true);

    if (requestGeo) {
      xhr.setRequestHeader('access-control-expose-headers', 'geo-region');
    }

    /**
     * Content-Type of application/json triggers a preflight OPTIONS request,
     * so when we can, mark our request as text/plain (even though it contains JSON).
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS
     */

    if (/post|put|patch/i.test(method) || contentType) {
      xhr.setRequestHeader('Content-Type', contentType ? contentType : 'text/plain');
    }

    xhr.send(data ? JSON.stringify(data) : data);
  });
}
